<template>
  <el-select v-model="value" placeholder="请选择" class="selector">
    <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value">
    </el-option>
  </el-select>
</template>

<script>
import {date2DateString} from "@/util/date";

export default {
  name: "DateSelector",
  watch: {
    value(newValue) {
      const date = new Date();
      let startTime = '';
      let endTime = date2DateString(date);
      switch (newValue) {
        case 1:
          startTime = `${this.getDate(new Date(), -7)} 00:00:00`;
          this.$emit('date-changed', {
            type: 'week',
            startTime,
            endTime,
          });
          break;
        case 2:
          startTime = `${this.getDate(new Date(), -29)} 00:00:00`;
          this.$emit('date-changed', {
            type: 'month',
            startTime,
            endTime,
          });
          break;
        default:
          break;
      }
    },
  },
  data() {
    return {
      value: '',
      options: [
        {
          label: '近一周',
          value: 1,
        },
        {
          label: '近一月',
          value: 2,
        },
      ]
    };
  },
  created() {
    this.value = 2;
  },
  methods: {
    getDate(date, days) {
      date.setDate(date.getDate() + days);
      const year = date.getFullYear(),
          month = (date.getMonth() + 1 + '').padStart(2, '0'),
          day = (date.getDate() + '').padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  }
}
</script>

<style lang="scss" scoped>


</style>
