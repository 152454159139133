const parkingListApi = '/v1/private/app-user-api/wx-admin-api/all-parking';
const parkingDataApi = '/v1/private/app-user-api/wx-admin-api/parking-forms/overview';
const parkingIncomeAnalysisApi = '/v1/private/app-user-api/wx-admin-api/parking-forms/charge-type-proportion';
const parkingIncomeStatisticsApi = '/v1/private/app-user-api/wx-admin-api/parking-forms/income-analysis';
const parkingDurationApi = '/v1/private/app-user-api/wx-admin-api/parking-forms/percentage-parking-time';
const turnOverApi = '/v1/private/app-user-api/wx-admin-api/parking-forms/parking-turnover-rate';
const freeVehicleApi = '/v1/private/app-user-api/wx-admin-api/free-vehicle-page';
const addFreeVehicleApi = '/v1/private/app-user-api/wx-admin-api/free-vehicle';
const editFreeVehicleApi = '/v1/private/app-user-api/wx-admin-api/free-vehicle';
const deleteFreeVehicleApi = '/v1/private/app-user-api/wx-admin-api/free-vehicle';
const enabledFreeVehicleApi = '/v1/private/app-user-api/wx-admin-api/free-vehicle-enabled';
const allParkingIncomeStatisticsApi = '/v1/private/app-user-api/wx-admin-api/parking-forms/operation';
const allParkingDataApi = '/v1/private/app-user-api/wx-admin-api/parking-forms/base-info';
const allParkingIncomeRankingApi = '/v1/private/app-user-api/wx-admin-api/parking-forms/income-ranking';

export {
    parkingListApi,
    parkingDataApi,
    parkingIncomeAnalysisApi,
    parkingIncomeStatisticsApi,
    parkingDurationApi,
    turnOverApi,
    freeVehicleApi,
    addFreeVehicleApi,
    editFreeVehicleApi,
    deleteFreeVehicleApi,
    enabledFreeVehicleApi,
    allParkingIncomeStatisticsApi,
    allParkingDataApi,
    allParkingIncomeRankingApi,
};
