import Vue from 'vue'
import parking from "./modules/parking";
import user from "./modules/user";
import Vuex from "vuex";
import getters from "./getters";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        parking,
        user,
    },
    getters,
});
export default store;

