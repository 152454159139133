import Vue from 'vue'
import App from './App.vue'
import store from "./store";
import router from "@/router";
import "./style/index.css"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import rem from "@/util/rem";
import FastClick from 'fastclick'

rem();
FastClick.attach(document.body);

Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')
