<template>
  <div ref="chart" class="container"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "ParkingDuration",
  props: {
    chartData: {
      default: [],
    }
  },
  watch: {
    chartData: function () {
      this.initChart();
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.chart = echarts.init(this.$refs.chart);
  },
  methods: {
    // 格式化数据
    formatData() {
      let {chartData} = this;
      let xAxisData = [];
      let series;
      chartData.map(({key, values}) => {
        xAxisData.push(key);
        if (!series) {
          series = new Array(values.length);
        }
        values.map(({item, value}, index) => {
          if (!series[index]) {
            series[index] = {
              name: item,
              type: 'bar',
              barWidth: '20px',
              stack: 'Search Engine',
              emphasis: {
                focus: 'series'
              },
              data: [],
            };
          }
          series[index].data.push(value);
        });
      });
      return {
        xAxisData,
        series,
      }
    },
    // 初始化图表
    initChart() {
      let {xAxisData, series} = this.formatData();
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          top: '15%',
          left: '0%',
          right: '0%',
          bottom: '0%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            rotate: '90', // 旋转30度
            data: xAxisData,
            axisLabel: {
              rotate: '-30',
              interval: 0,
              fontSize: 9,
            },
            axisTick: {
              show: false,
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '%',
            axisLabel: {
              fontSize: 9,
            },
          }
        ],
        series
      };
      option && this.chart.setOption(option);
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: .2rem;
  width: 100%;
  height: 4rem;
  background: #f5f5f5;
}
</style>
