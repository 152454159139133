import request from "@/api/request";
import {
    addFreeVehicleApi,
    deleteFreeVehicleApi,
    editFreeVehicleApi,
    enabledFreeVehicleApi,
    freeVehicleApi
} from "@/api/apis";

const getFreeVehicleList = ({plate, remark, pageNum, pageSize = 10}) => {
    return request({
        url: freeVehicleApi,
        params: {
            plate, remark, pageNum, pageSize
        }
    });
};

const addFreeVehicle = ({plate, parkingId, type, remark}) => {
    return request({
        url: addFreeVehicleApi,
        method: 'post',
        data: {
            plate, parkingId, type, remark
        }
    });
};

const editFreeVehicle = ({id, plate, parkingId, type, remark}) => {
    return request({
        url: editFreeVehicleApi,
        method: 'put',
        data: {
            id, plate, parkingId, type, remark
        }
    });
};

const deleteFreeVehicle = ({id}) => {
    return request({
        url: deleteFreeVehicleApi + '?id=' + id,
        method: 'delete',
    });
};

const enabledFreeVehicle = ({id}) => {
    return request({
        url: enabledFreeVehicleApi,
        method: 'put',
        data: {
            id
        }
    });
};


export {
    getFreeVehicleList,
    addFreeVehicle,
    editFreeVehicle,
    deleteFreeVehicle,
    enabledFreeVehicle,
};
