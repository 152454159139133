import Home from "@/pages/home";
import FreeVehicle from "@/pages/free_vehicle/list";
import ParkingAnalyze from "@/pages/parking_analyze/home";
import ParkingManagement from "@/pages/parking_management/home";
import AddFreeVehicle from "@/pages/free_vehicle/add";

const routes = [
    {path: '/', component: Home},
    {path: '/free-vehicle', component: FreeVehicle},
    {path: '/parking-management', component: ParkingManagement},
    {path: '/parking-analyze', component: ParkingAnalyze},
    {path: '/add-free-vehicle', component: AddFreeVehicle, name: 'add-free-vehicle'},
];

export default routes;
