import axios from 'axios';
import qs from 'qs';
import JSONBig from 'json-bigint';
import store from "@/store";

const service = axios.create({
    baseURL: 'https://miniapp.codfly.cn/api/',
    timeout: 5000,
    paramsSerializer: {
        serialize: function (params) {
            return qs.stringify(params, {arrayFormat: 'repeat'})
        }
    },
    transformResponse: [function (data) {
        try {
            const json = JSONBig({storeAsString: true});
            return json.parse(data);
        } catch (e) {
            return data;
        }
    }]
});

service.interceptors.request.use(
    config => {
        config.headers['authorization'] = store.getters.token;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    response => {
        const res = response.data;
        if (res.code !== 0) {
            return Promise.reject(new Error(res.message || 'Error'));
        } else {
            return res.data;
        }
    },
    error => {
        return Promise.reject(error);
    }
);

export default service;
