import {getParkingList} from "@/api";

const state = {
    parkingList: [],
};

const mutations = {
    SET_PARKING_LIST: (state, parkingList) => {
        state.parkingList = parkingList;
    },
};

const actions = {
    getParkingOptions({commit}) {
        getParkingList().then(parkingList => {
            if (!parkingList) {
                parkingList = [];
            }
            parkingList.forEach(item => {
                item.parkingName = item.name;
            });
            commit('SET_PARKING_LIST', parkingList);
        }).catch(e => {
            console.log(e);
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
