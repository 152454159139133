<template>
  <div class="container">
    <div class="title">工作台</div>
    <div class="list">
      <div v-for="item in menuList" :key="item.title" class="item" @click="jumpToMenuModule(item)">
        <img :src="item.iconPath"/>
        <span>{{ item.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  data() {
    return {
      menuList: [
        {
          iconPath: require("@/assets/icon_menu_1.png"),
          title: '免费车辆',
          routerPath: 'free-vehicle',
        },
        {
          iconPath: require("@/assets/icon_menu_2.png"),
          title: '车场管理',
          routerPath: 'parking-management',
        },
        {
          iconPath: require("@/assets/icon_menu_3.png"),
          title: '车场数据',
          routerPath: 'parking-analyze',
        }
      ],
    };
  },
  methods: {
    // 跳转菜单模块
    jumpToMenuModule(item) {
      this.$router.push(item.routerPath);
    }
  }
}
</script>

<style lang="scss" scoped>

.container {
  padding: 0 .3rem;

  .title {
    margin: .3rem 0;
    font-size: 0.3rem;
    font-weight: 600;
  }

  .list {
    display: flex;
    flex-wrap: wrap;

    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 0.28rem;
      width: 25%;
      padding: .3rem;

      img {
        margin-bottom: .05rem;
        width: .5rem;
        height: .5rem;
      }
    }
  }
}

</style>
