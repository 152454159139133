<template>
  <div class="container">
    <div class="search-bar">
      <el-input
          class="input"
          placeholder="请输入车牌号码/备注内容"
          prefix-icon="el-icon-search"
          v-model="keyword">
        <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
      </el-input>
    </div>
    <el-empty v-if="dataSource.length === 0" :image-size="100" description="暂无免费车辆记录"></el-empty>
    <ul v-else class="list" v-infinite-scroll="loadMore" style="overflow:auto">
      <li v-for="item in dataSource" :key="item.id" class="infinite-list-item">
        <el-card class="item">
          <div class="plate">{{ item.plate }}</div>
          <div class="parking-name">停车场名称：{{ item.parkingName }}</div>
          <div class="type">免费类型：{{ item.type === 1 ? '单次放行' : item.type === 2 ? '永久有效' : '' }}</div>
          <div class="remark">备注：{{ item.remark }}</div>
          <el-row type="flex" justify="end">
            <el-button v-if="item.status !== 1" type="primary" size="small" round @click="enabled(item)">启用</el-button>
            <el-button v-else type="primary" size="small" round @click="edit(item)">编辑</el-button>
            <el-button type="danger" size="small" round @click="del(item)">删除</el-button>
          </el-row>
        </el-card>
      </li>
    </ul>
    <div class="bottom">
      <el-button type="primary" class="btn" @click="jumpToAddFreeVehicle">添加免费车辆</el-button>
    </div>
  </div>
</template>

<script>
import {deleteFreeVehicle, enabledFreeVehicle, getFreeVehicleList} from "@/api";

export default {
  name: "FreeVehicle",
  data() {
    return {
      pageNum: 1,
      keyword: '',
      dataSource: [],
    };
  },
  created() {
    this.getFreeVehicleList();
  },
  methods: {
    // 启用
    enabled(item) {
      let _this = this;
      this.$alert('确定启用该免费车辆吗?', '提示', {
        confirmButtonText: '确定',
        callback: action => {
          if (action === 'confirm') {
            _this.enabledFreeVehicle(item);
          }
        }
      });
    },
    // 编辑
    edit(item) {
      this.$router.push({name: 'add-free-vehicle', params: item});
    },
    // 删除
    del(item) {
      let _this = this;
      this.$alert('确定删除该免费车辆吗?', '提示', {
        confirmButtonText: '确定',
        callback: action => {
          if (action === 'confirm') {
            _this.deleteFreeVehicle(item);
          }
        }
      });
    },
    // 搜索
    search() {
      this.pageNum = 1;
      this.getFreeVehicleList();
    },
    // 跳转添加免费车辆
    jumpToAddFreeVehicle() {
      this.$router.push('add-free-vehicle');
    },
    // 获取免费车辆列表
    async getFreeVehicleList() {
      let {keyword, pageNum} = this;
      let {items} = await getFreeVehicleList({plate: keyword, pageNum});
      if (items && items.length !== 0) {
        if (pageNum === 1) {
          this.dataSource = items;
        } else {
          this.dataSource = this.dataSource.concat(items);
        }
      } else if (items.length === 0 && pageNum === 1){
        this.dataSource = items
      }else {
        pageNum--;
      }
    },
    // 启用免费车辆
    async enabledFreeVehicle(item) {
      try {
        let {id} = item;
        await enabledFreeVehicle({id});
        this.$message.success('操作成功');
        item.status = 1;
      } catch (e) {
        this.$message.error(e);
      }
    },
    // 删除免费车辆
    async deleteFreeVehicle(item) {
      try {
        let {id} = item;
        await deleteFreeVehicle({id});
        this.$message.success('删除成功');
        this.search();
      } catch (e) {
        this.$message.error(e);
      }
    },
    // 加载更多
    loadMore() {
      this.pageNum++;
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding-top: 1rem;
  padding-bottom: 1.5rem;

  .search-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 1rem;
    padding: .1rem .3rem;
    background: #fff;
  }

  .list {
    .item {
      margin: .3rem;

      display: flex;
      flex-direction: column;

      .plate {
        font-size: .36rem;
        font-weight: 600;
      }

      .parking-name {
        margin: .1rem 0;
        font-size: .28rem;
      }

      .type {
        margin: .1rem 0;
        font-size: .28rem;
      }

      .remark {
        font-size: .28rem;
      }

    }
  }

  .bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: .2rem;
    padding-bottom: .5rem;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn {
      width: 6.9rem;
      height: .8rem;
      font-size: .28rem;
    }
  }
}

</style>
