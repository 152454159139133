<template>
  <div class="container">
    <div ref="chart" class="container">
    </div>
    <div class="total-income">停车缴费<br/><span class="value">￥{{ totalIncome }}</span></div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "ParkingIncomeAnalysis",
  props: {
    totalIncome: {
      default: 0
    },
    chartData: {
      default: [],
    }
  },
  watch: {
    chartData: function () {
      this.initChart();
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.chart = echarts.init(this.$refs.chart);
  },
  methods: {
    // 格式化数据
    formatData() {
      let {chartData} = this;
      let seriesData = chartData.map(({item, value}) => {
        return {
          name: item,
          value
        };
      });
      return {
        seriesData,
      }
    },
    // 初始化图表
    initChart() {
      let {seriesData} = this.formatData();
      let option = {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '车库收入分析',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: seriesData,
          }
        ]
      };
      option && this.chart.setOption(option);
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: .2rem;
  width: 100%;
  height: 4rem;
  background: #f5f5f5;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .total-income {
    position: absolute;
    text-align: center;
    font-size: .26rem;

    .value {
      font-size: .3rem;
      font-weight: 600;
    }
  }
}
</style>
