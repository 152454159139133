<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

import {RouterView} from "vue-router"

export default {
  name: 'App',
  components: {
    RouterView,
  },
  mounted() {
    this.$store.dispatch('parking/getParkingOptions');
    let url = window.location.href;
    let path = url.split('?')[1] || '';
    if (!path) {
      return this.$message.error('登录异常');
    } else {
      let token = path.split('#')[0] || '';
      if (!token) {
        return this.$message.error('登录异常');
      }
      this.$store.dispatch('user/setToken', token);
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #424242;
}
</style>
