const rem = () => {
    let deviceWidth = document.documentElement.clientWidth;
    if (deviceWidth > 750)
        deviceWidth = 750;
    document.documentElement.style.fontSize = deviceWidth / 7.5 + "px";
};

document.documentElement.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
        event.preventDefault();
    }
}, false);

let lastTouchEnd = 0;
document.documentElement.addEventListener('touchend', function (event) {
    let now = Date.now();
    if (now - lastTouchEnd <= 300) {
        event.preventDefault();
    }
    lastTouchEnd = now;
}, false);

export default rem;
