import request from "@/api/request";
import {
    allParkingDataApi,
    allParkingIncomeRankingApi,
    allParkingIncomeStatisticsApi,
    parkingDataApi,
    parkingDurationApi,
    parkingIncomeAnalysisApi,
    parkingIncomeStatisticsApi,
    parkingListApi,
    turnOverApi
} from "@/api/apis";


const getParkingList = () => {
    return request({
        url: parkingListApi,
    });
};

const getParkingData = ({ids, startTime, endTime}) => {
    return request({
        url: parkingDataApi,
        params: {
            ids,
            startTime,
            endTime
        }
    });
};

const getAllParkingData = ({ids, startTime, endTime}) => {
    return request({
        url: allParkingDataApi,
        params: {
            ids,
            startTime,
            endTime
        }
    });
}

const getParkingIncomeAnalysis = ({ids, startTime, endTime}) => {
    return request({
        url: parkingIncomeAnalysisApi,
        params: {
            ids,
            startTime,
            endTime
        }
    });
};

const getParkingIncomeStatistics = ({ids, startTime, endTime}) => {
    return request({
        url: parkingIncomeStatisticsApi,
        params: {
            ids,
            startTime,
            endTime
        }
    });
};

const getAllParkingIncomeStatistics = ({ids, startTime, endTime}) => {
    return request({
        url: allParkingIncomeStatisticsApi,
        params: {
            ids,
            startTime,
            endTime
        }
    });
};

const getParkingDuration = ({ids, startTime, endTime}) => {
    return request({
        url: parkingDurationApi,
        params: {
            ids,
            startTime,
            endTime
        }
    });
};

const getTurnOver = ({ids, startTime, endTime}) => {
    return request({
        url: turnOverApi,
        params: {
            ids,
            startTime,
            endTime
        }
    });
};

const getAllParkingIncomeRanking = ({ids, startTime, endTime}) => {
    return request({
        url: allParkingIncomeRankingApi,
        params: {
            ids,
            startTime,
            endTime
        }
    });
};

export {
    getParkingList,
    getParkingData,
    getAllParkingData,
    getParkingIncomeAnalysis,
    getParkingIncomeStatistics,
    getAllParkingIncomeStatistics,
    getParkingDuration,
    getTurnOver,
    getAllParkingIncomeRanking,
};
