/**
 * 时间格式转时间戳
 * @param date
 */
const date2Timestamp = date => {
    if (!date) {
        return new Date().getTime();
    }
    try {
        return new Date(date.replace(/-/g, '/')).getTime();
    } catch {
        return new Date(date).getTime();
    }
};

/**
 * 时间戳转时间格式的字符串
 * @param timestamp 时间戳
 * @param formatter 格式
 */
const timestamp2DateString = (timestamp, formatter = 'yyyy-MM-dd HH:mm:ss') => {
    if (!timestamp) return '-';
    let date = new Date(timestamp),
        year = date.getFullYear(),
        month = date.getMonth() + 1,
        day = date.getDate(),
        hour = date.getHours(),
        min = date.getMinutes(),
        sec = date.getSeconds(),
        formatNumber = n => {
            return n < 10 ? `0${n}` : `${n}`;
        };
    return formatter.replace('yyyy', year.toString()).replace('MM', formatNumber(month)).replace('dd', formatNumber(day)).replace('HH', formatNumber(hour)).replace('mm', formatNumber(min)).replace('ss', formatNumber(sec));
};

const date2DateString = date => {
    return timestamp2DateString(date2Timestamp(date));
};

const dateString2Date = dateString => {
    try {
        return new Date(dateString.replace(/-/g, '/'));
    } catch {
        return new Date(dateString);
    }
};

const formatChartData = (data, endTime, type) => {
    if (type === '') return data;
    if (data && data.length !== 0) {
        let nowTimestamp = date2Timestamp(date2DateString(endTime));
        const formatNumber = num => {
            if (num < 10) return `0${num}`;
            return num;
        };

        return data.map(({ values }, index) => {
            let key = '';
            if (type === 'day') {
                key = formatNumber(index);
            }
            if (type === 'week') {
                let timestamp = nowTimestamp - (data.length - 1 - index) * 24 * 60 * 60 * 1000;
                key = timestamp2DateString(timestamp, 'MM-dd');
            }
            if (type === 'month') {
                let timestamp = nowTimestamp - (data.length - 1 - index) * 24 * 60 * 60 * 1000;
                key = timestamp2DateString(timestamp, 'MM-dd');
            }
            if (type === 'year') {
                let timestamp = nowTimestamp - (data.length - 1 - index) * 31 * 24 * 60 * 60 * 1000;
                key = timestamp2DateString(timestamp, 'yyyy-MM');
            }
            return {
                key,
                values
            };
        });
    }
    return [];
};

export {
    date2Timestamp,
    timestamp2DateString,
    date2DateString,
    dateString2Date,
    formatChartData,
};
