<template>
  <div class="container">
    <div class="title">选择停车场</div>
    <el-select v-model="parkingIds" multiple placeholder="全部" class="selector" clearable>
      <el-option
          v-for="item in parkingList"
          :key="item.id"
          :label="item.parkingName"
          :value="item.id">
      </el-option>
    </el-select>
    <el-card style="margin-top: .3rem">
      <div class="title">时间范围</div>
      <el-row type="flex" justify="center" align="middle">
        <el-date-picker
            v-model="startTime"
            type="date"
            :clearable="false"
            @focus="forbid"
            :picker-options="startTimePickerOptions"
            placeholder="选择开始时间">
        </el-date-picker>
        <div class="line">-</div>
        <el-date-picker
            v-model="endTime"
            type="date"
            :clearable="false"
            @focus="forbid"
            :picker-options="endTimePickerOptions"
            placeholder="选择结束时间">
        </el-date-picker>
      </el-row>
      <div class="title" style="margin-top: .3rem">累计收入</div>
      <div class="total-income">￥{{ totalIncome }}</div>
      <div class="data-content">
        <el-row type="flex" justify="center" align="middle">
          <div class="item">
            <div class="value">{{ parkingCount }}</div>
            <div class="label">停车场数量</div>
          </div>
          <div class="item border">
            <div class="value">{{ berthCount }}</div>
            <div class="label">总车位</div>
          </div>
          <div class="item">
            <div class="value">{{ parkingTimes }}</div>
            <div class="label">累计停车次数</div>
          </div>
        </el-row>
        <div class="compare">同比 <span
            :class="yearOverYear >= 0 ? 'up' : 'down'">{{ yearOverYear >= 0 ? '▲' : '▼' }}</span> {{ yearOverYear }}% 环比
          <span :class="chain >= 0 ? 'up' : 'down'">{{ chain >= 0 ? '▲' : '▼' }}</span> {{ chain }}%
        </div>
      </div>
    </el-card>
    <el-card style="margin-top: .3rem">
      <el-row type="flex" justify="space-between" align="middle">
        <div class="title">车库营收统计</div>
        <date-selector style="width: 2rem" @date-changed="incomeStatisticsDateChanged"></date-selector>
      </el-row>
      <parking-income-statistics :chart-data="parkingIncomeStatisticsData"></parking-income-statistics>
    </el-card>
    <el-card style="margin-top: .3rem">
      <el-row type="flex" justify="space-between" align="middle">
        <div class="title">收入排名</div>
        <date-selector style="width: 2rem" @date-changed="incomeRankingDateChanged"></date-selector>
      </el-row>
      <ul class="list">
        <li v-for="(item,index) in incomeRankingData" :key="item.title" class="item">
          <el-row type="flex" align="middle">
            <div class="no">{{ index + 1 }}</div>
            <span class="title">{{ item.title }}</span>
          </el-row>
          <span class="value">￥{{ item.value }}</span>
        </li>
      </ul>
    </el-card>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {date2DateString, dateString2Date, formatChartData} from "@/util/date";
import ParkingIncomeStatistics from "./parking_income_statistics/index";
import DateSelector from "@/pages/date_selector/index";
import {getAllParkingData, getAllParkingIncomeRanking, getAllParkingIncomeStatistics} from "@/api";

export default {
  name: "ParkingAnalyze",
  computed: {
    ...mapGetters(['parkingList']),
  },
  components: {
    ParkingIncomeStatistics,
    DateSelector
  },
  watch: {
    parkingIds: function (newVal, oldVal) {
      if (newVal === oldVal) return;
      this.getAllParkingData();
      this.getIncomeStatistics();
      this.getIncomeRanking();
    },
    startTime: function (newVal, oldVal) {
      if (newVal === oldVal) return;
      if (!oldVal) return;
      this.getAllParkingData();
    },
    endTime: function (newVal, oldVal) {
      if (newVal === oldVal) return;
      if (!oldVal) return;
      this.getAllParkingData();
    }
  },
  data() {
    return {
      parkingIds: [],
      startTime: '',
      endTime: '',
      startTimePickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      endTimePickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      totalIncome: 0,
      parkingCount: 0,
      berthCount: 0,
      parkingTimes: 0,
      yearOverYear: 0,
      chain: 0,
      incomeStatisticsType: '',
      incomeStatisticsStartTime: '',
      incomeStatisticsEndTime: '',
      incomeRankingStartTime: '',
      incomeRankingEndTime: '',
      parkingIncomeStatisticsData: [],
      incomeRankingData: [],
    };
  },
  created() {
    setTimeout(() => {
      this.setDefaultData();
    }, 0);
  },
  methods: {
    // 设置默认数据
    setDefaultData() {
      let date = new Date();
      date.setDate(date.getDate() - 30);
      const year = date.getFullYear(),
          month = date.getMonth() + 1,
          day = date.getDate();
      this.startTime = dateString2Date(`${year}-${month}-${day}`);
      this.endTime = new Date();
      this.parkingIds = [];
    },
    //禁止软键盘弹出
    forbid() {
      document.activeElement.blur();
    },
    // 营收统计时间修改
    incomeStatisticsDateChanged({type, startTime, endTime}) {
      this.incomeStatisticsType = type;
      this.incomeStatisticsStartTime = startTime;
      this.incomeStatisticsEndTime = endTime;
      this.getIncomeStatistics();
    },
    // 收入排名时间修改
    incomeRankingDateChanged({startTime, endTime}) {
      this.incomeRankingStartTime = startTime;
      this.incomeRankingEndTime = endTime;
      this.getIncomeRanking();
    },
    // 获取停车场数据
    async getAllParkingData() {
      let {parkingIds, startTime, endTime, parkingList} = this;
      let ids = parkingIds;
      if (ids.length === 0) {
        ids = parkingList.map(item => item.id);
      }
      if (ids.length === 0) return;
      let {berthCount, chain, income, parkingTimes, yearOverYear} = await getAllParkingData({
        ids,
        startTime: date2DateString(startTime),
        endTime: date2DateString(endTime)
      });
      this.berthCount = berthCount || 0;
      this.parkingTimes = parkingTimes || 0;
      this.parkingCount = ids.length;
      income = (income / 100).toFixed(2);
      this.totalIncome = income || 0;
      chain = chain || 0;
      yearOverYear = yearOverYear || 0;
      this.chain = chain * 100;
      this.yearOverYear = yearOverYear * 100;
    },
    // 获取营收统计
    async getIncomeStatistics() {
      let {parkingIds, incomeStatisticsType, incomeStatisticsStartTime, incomeStatisticsEndTime, parkingList} = this;
      let ids = parkingIds;
      if (ids.length === 0) {
        ids = parkingList.map(item => item.id);
      }
      if (ids.length === 0) return;
      let {chartData} = await getAllParkingIncomeStatistics({
        ids,
        startTime: incomeStatisticsStartTime,
        endTime: incomeStatisticsEndTime
      });
      chartData.forEach(({values}) => {
        values.forEach(item => {
          item.value = (item.value / 100.0).toFixed(2);
        });
      });
      this.parkingIncomeStatisticsData = formatChartData(chartData, incomeStatisticsEndTime, incomeStatisticsType);
    },
    // 获取收入排名
    async getIncomeRanking() {
      let {parkingIds, incomeRankingStartTime, incomeRankingEndTime, parkingList} = this;
      let ids = parkingIds;
      if (ids.length === 0) {
        ids = parkingList.map(item => item.id);
      }
      if (ids.length === 0) return;
      let incomeRankingData = await getAllParkingIncomeRanking({
        ids,
        startTime: incomeRankingStartTime,
        endTime: incomeRankingEndTime
      });
      incomeRankingData.forEach(item => {
        item.exactValue = (item.exactValue / 100).toFixed(2);
        item.value = (item.value / 100.0).toFixed(2);
      });
      this.incomeRankingData = incomeRankingData;
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: .3rem;

  .title {
    margin: .1rem 0;
    font-size: 0.3rem;
    font-weight: 600;
  }

  .line {
    margin: 0 .3rem;
  }

  .selector {
    width: 100%;
  }

  .total-income {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
  }

  .data-content {
    margin-top: .3rem;
    padding: .3rem 0 .1rem 0;
    background: #f5f5f5;
    border-radius: .1rem;

    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;

      .value {
        font-size: .26rem;
        font-weight: 600;
      }

      .label {
        font-size: .26rem;
      }
    }

    .border {
      border-left: .02rem solid #ccc;
      border-right: .02rem solid #ccc;
    }
  }

  .compare {
    margin-top: .2rem;
    margin-left: .3rem;
    font-size: .2rem;

    .up {
      color: red;
    }

    .down {
      color: green;
    }
  }

  .list {
    margin-top: .1rem;

    .item {
      height: .8rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .no {
        background: #f5f5f5;
        width: .5rem;
        height: .5rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .28rem;
        font-weight: bold;
      }

      .title {
        margin-left: .1rem;
        font-size: .28rem;
      }

      .value {
        font-size: .28rem;
        font-weight: 600;
      }
    }
  }
}
</style>
