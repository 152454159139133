<template>
  <div class="container">
    <div class="title">请输入车牌号码</div>
    <el-input v-model="plate" placeholder="请输入车牌号码，如粤B123456"/>
    <div class="title">请选择停车场</div>
    <el-select v-model="parkingId" placeholder="请选择停车场" class="selector">
      <el-option
          v-for="item in parkingList"
          :key="item.id"
          :label="item.parkingName"
          :value="item.id">
      </el-option>
    </el-select>
    <div class="title">免费类型</div>
    <el-select v-model="freeType" placeholder="请选择免费类型" class="selector">
      <el-option
          v-for="item in freeTypeOptions"
          :key="item.id"
          :label="item.label"
          :value="item.id">
      </el-option>
    </el-select>
    <div class="title">备注</div>
    <el-input
        type="textarea"
        :autosize="{ minRows: 5, maxRows: 10}"
        placeholder="请输入备注"
        v-model="remark">
    </el-input>
    <el-button type="primary" class="btn" @click="submit">确定</el-button>
  </div>
</template>

<script>
import {addFreeVehicle, editFreeVehicle} from "@/api";
import {mapGetters} from "vuex";

export default {
  name: "AddFreeVehicle",
  computed: {
    ...mapGetters(['parkingList']),
  },
  data() {
    return {
      id: '',
      plate: '',
      parkingId: '',
      freeType: '',
      remark: '',
      freeTypeOptions: [
        {
          label: '永久有效',
          id: 2,
        },
        {
          label: '单次放行',
          id: 1,
        }
      ]
    }
  },
  mounted() {
    let params = this.$route.params;
    if (params?.id) {
      let {id, plate, parkingId, type, remark} = params;
      this.plate = plate;
      this.parkingId = parkingId;
      this.freeType = type;
      this.remark = remark;
      this.id = id;
    }
  },
  methods: {
    // 提交
    async submit() {
      try {
        let {id, plate, parkingId, freeType, remark} = this;
        if (!plate) {
          return this.$message.warning('请输入车牌号码');
        }
        if (!parkingId) {
          return this.$message.warning('请选择停车场');
        }
        if (!freeType) {
          return this.$message.warning('请选择免费类型');
        }
        if (!remark) {
          return this.$message.warning('请输入备注');
        }
        if (id) {
          await editFreeVehicle({id, plate, parkingId, type: freeType, remark});
        } else {
          await addFreeVehicle({plate, parkingId, type: freeType, remark});
        }
        this.$message.success('操作成功');
        this.$router.back();
      } catch (e) {
        this.$message.error(e);
      }
    }

  }
}
</script>

<style lang="scss" scoped>

.container {
  padding: .3rem;

  .title {
    margin: .3rem 0 .1rem 0;
    font-size: 0.3rem;
    font-weight: 600;
  }

  .selector {
    width: 100%;
  }

  .btn {
    margin-top: .5rem;
    width: 6.9rem;
    height: .8rem;
    font-size: .28rem;
  }
}

</style>
