<template>
  <div class="container">
    <div class="title">选择停车场</div>
    <el-select v-model="parkingId" placeholder="请选择停车场" class="selector">
      <el-option
          v-for="item in parkingList"
          :key="item.id"
          :label="item.parkingName"
          :value="item.id">
      </el-option>
    </el-select>
    <el-card style="margin-top: .3rem">
      <div class="title">数据总览</div>
      <el-row type="flex" justify="center" align="middle">
        <el-date-picker
            v-model="startTime"
            type="date"
            :clearable="false"
            @focus="forbid"
            :picker-options="startTimePickerOptions"
            placeholder="选择开始时间">
        </el-date-picker>
        <div class="line">-</div>
        <el-date-picker
            v-model="endTime"
            type="date"
            :clearable="false"
            @focus="forbid"
            :picker-options="endTimePickerOptions"
            placeholder="选择结束时间">
        </el-date-picker>
      </el-row>
      <div class="data-content">
        <el-row type="flex" justify="center" align="middle">
          <div class="item">
            <div class="value">{{ income }}</div>
            <div class="label">累计收入(元)</div>
          </div>
          <div class="item border">
            <div class="value">{{ berthCount }}</div>
            <div class="label">总车位(个)</div>
          </div>
          <div class="item">
            <div class="value">{{ averageTurnoverRate }}</div>
            <div class="label">平均周转率</div>
          </div>
        </el-row>
        <div class="compare">同比 <span
            :class="yearOverYear >= 0 ? 'up' : 'down'">{{ yearOverYear >= 0 ? '▲' : '▼' }}</span> {{ yearOverYear }}% 环比
          <span :class="chain >= 0 ? 'up' : 'down'">{{ chain >= 0 ? '▲' : '▼' }}</span> {{ chain }}%
        </div>
      </div>
    </el-card>
    <el-card style="margin-top: .3rem">
      <el-row type="flex" justify="space-between" align="middle">
        <div class="title">车库收入分析</div>
        <date-selector style="width: 2rem" @date-changed="incomeAnalysisDateChanged"></date-selector>
      </el-row>
      <parking-income-analysis :chart-data="parkingIncomeAnalysisData"
                               :total-income="totalIncome"></parking-income-analysis>
    </el-card>
    <el-card style="margin-top: .3rem">
      <el-row type="flex" justify="space-between" align="middle">
        <div class="title">车库营收统计</div>
        <date-selector style="width: 2rem" @date-changed="incomeStatisticsDateChanged"></date-selector>
      </el-row>
      <parking-income-statistics :chart-data="parkingIncomeStatisticsData"></parking-income-statistics>
    </el-card>
    <el-card style="margin-top: .3rem">
      <el-row type="flex" justify="space-between" align="middle">
        <div class="title">停车时长分析</div>
        <date-selector style="width: 2rem" @date-changed="parkingDurationDateChanged"></date-selector>
      </el-row>
      <parking-duration :chart-data="parkingDurationData"/>
    </el-card>
    <el-card style="margin-top: .3rem">
      <el-row type="flex" justify="space-between" align="middle">
        <div class="title">车位周转率统计</div>
        <date-selector style="width: 2rem" @date-changed="turnOverDateChanged"></date-selector>
      </el-row>
      <turn-over :chart-data="turnOverData"/>
    </el-card>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {
  getParkingData,
  getParkingDuration,
  getParkingIncomeAnalysis,
  getParkingIncomeStatistics,
  getTurnOver
} from "@/api";
import {date2DateString, dateString2Date, formatChartData} from "@/util/date";
import DateSelector from "../../date_selector/index";
import ParkingDuration from "./parking_druation/index";
import ParkingIncomeAnalysis from "./parking_income_analysis/index";
import ParkingIncomeStatistics from "./parking_income_statistics/index";
import TurnOver from "./turn_over/index";

export default {
  name: "ParkingManagement",
  computed: {
    ...mapGetters(['parkingList']),
  },
  components: {
    DateSelector,
    ParkingDuration,
    ParkingIncomeAnalysis,
    ParkingIncomeStatistics,
    TurnOver,
  },
  watch: {
    parkingId: function (newVal, oldVal) {
      this.getParkingData();
      if (newVal === oldVal) return;
      if (!oldVal) return;
      this.getIncomeAnalysis();
      this.getIncomeStatistics();
      this.getParkingDuration();
      this.getTurnOver();
    },
    startTime: function (newVal, oldVal) {
      if (newVal === oldVal) return;
      if (!oldVal) return;
      this.getParkingData();
    },
    endTime: function (newVal, oldVal) {
      if (newVal === oldVal) return;
      if (!oldVal) return;
      this.getParkingData();
    }
  },
  data() {
    return {
      parkingId: '',
      startTime: '',
      endTime: '',
      berthCount: 0,
      chain: 0,
      income: 0,
      yearOverYear: 0,
      averageTurnoverRate: '',
      startTimePickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      endTimePickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      incomeAnalysisStartTime: '',
      incomeAnalysisEndTime: '',
      incomeStatisticsType: '',
      incomeStatisticsStartTime: '',
      incomeStatisticsEndTime: '',
      parkingDurationStartTime: '',
      parkingDurationEndTime: '',
      turnOverType: '',
      turnOverStartTime: '',
      turnOverEndTime: '',
      parkingDurationData: [],
      parkingIncomeAnalysisData: [],
      totalIncome: 0,
      parkingIncomeStatisticsData: [],
      turnOverData:[],
    };
  },
  mounted() {
    setTimeout(() => {
      this.setDefaultData();
    }, 0);
  },
  methods: {
    // 收入分析时间修改
    incomeAnalysisDateChanged({startTime, endTime}) {
      this.incomeAnalysisStartTime = startTime;
      this.incomeAnalysisEndTime = endTime;
      this.getIncomeAnalysis();
    },
    // 营收统计时间修改
    incomeStatisticsDateChanged({type, startTime, endTime}) {
      this.incomeStatisticsType = type;
      this.incomeStatisticsStartTime = startTime;
      this.incomeStatisticsEndTime = endTime;
      this.getIncomeStatistics();
    },
    // 停车时长分析时间修改
    parkingDurationDateChanged({startTime, endTime}) {
      this.parkingDurationStartTime = startTime;
      this.parkingDurationEndTime = endTime;
      this.getParkingDuration();
    },
    // 车位周转率统计时间修改
    turnOverDateChanged({type, startTime, endTime}) {
      this.turnOverType = type;
      this.turnOverStartTime = startTime;
      this.turnOverEndTime = endTime;
      this.getTurnOver();
    },
    //禁止软键盘弹出
    forbid() {
      document.activeElement.blur();
    },
    // 设置初始数据
    setDefaultData() {
      let parking = this.parkingList[0];
      this.parkingId = parking.id || '';
      let date = new Date();
      date.setDate(date.getDate() - 30);
      const year = date.getFullYear(),
          month = date.getMonth() + 1,
          day = date.getDate();
      this.startTime = dateString2Date(`${year}-${month}-${day}`);
      this.endTime = new Date();
    },
    // 获取数据总览
    async getParkingData() {
      let {parkingId, startTime, endTime} = this;
      let {berthCount, chain, income, yearOverYear, averageTurnoverRate} = await getParkingData({
        ids: [parkingId],
        startTime: date2DateString(startTime),
        endTime: date2DateString(endTime)
      });
      this.berthCount = berthCount || 0;
      income = (income / 100).toFixed(2);
      this.income = income || 0;
      chain = chain || 0;
      yearOverYear = yearOverYear || 0;
      this.chain = chain * 100;
      this.yearOverYear = yearOverYear * 100;
      this.averageTurnoverRate = averageTurnoverRate || '0%';
    },
    // 获取收入分析
    async getIncomeAnalysis() {
      let {parkingId, incomeAnalysisStartTime, incomeAnalysisEndTime} = this;
      let {accumulateIncome, chartData} = await getParkingIncomeAnalysis({
        ids: [parkingId],
        startTime: incomeAnalysisStartTime,
        endTime: incomeAnalysisEndTime
      });
      this.totalIncome = (accumulateIncome / 100).toFixed(2);
      chartData.forEach(item=>{
        item.value = (item.value / 100).toFixed(2);
      });
      this.parkingIncomeAnalysisData = chartData;
    },
    // 获取营收统计
    async getIncomeStatistics() {
      let {parkingId, incomeStatisticsType, incomeStatisticsStartTime, incomeStatisticsEndTime} = this;
      let {chartData} = await getParkingIncomeStatistics({
        ids: [parkingId],
        startTime: incomeStatisticsStartTime,
        endTime: incomeStatisticsEndTime
      });
      this.parkingIncomeStatisticsData = formatChartData(chartData, incomeStatisticsEndTime, incomeStatisticsType);
    },
    // 获取停车时长
    async getParkingDuration() {
      let {parkingId, parkingDurationStartTime, parkingDurationEndTime} = this;
      let {chartData} = await getParkingDuration({
        ids: [parkingId],
        startTime: parkingDurationStartTime,
        endTime: parkingDurationEndTime
      });
      this.parkingDurationData = chartData;
    },
    // 获取车位周转率统计
    async getTurnOver() {
      let {parkingId, turnOverType, turnOverStartTime, turnOverEndTime} = this;
      let {chartData} = await getTurnOver({
        ids: [parkingId],
        startTime: turnOverStartTime,
        endTime: turnOverEndTime
      });
      this.turnOverData = formatChartData(chartData, turnOverEndTime, turnOverType);
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  padding: .3rem;

  .title {
    margin: .1rem 0;
    font-size: 0.3rem;
    font-weight: 600;
  }

  .line {
    margin: 0 .3rem;
  }

  .selector {
    width: 100%;
  }

  .data-content {
    margin-top: .3rem;
    padding: .3rem 0 .1rem 0;
    background: #f5f5f5;
    border-radius: .1rem;

    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;

      .value {
        font-size: .26rem;
        font-weight: 600;
      }

      .label {
        font-size: .26rem;
      }
    }

    .border {
      border-left: .02rem solid #ccc;
      border-right: .02rem solid #ccc;
    }
  }

  .compare {
    margin-top: .2rem;
    margin-left: .3rem;
    font-size: .2rem;

    .up {
      color: red;
    }

    .down {
      color: green;
    }
  }
}
</style>
